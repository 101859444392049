import React from 'react';
import ErrorBoundary from '../ErrorBoundary';
import Header from './Header';
import Footer from './Footer';
import SEO from './SEO';
import Cart from '../Cart';
import AudioPlayer from '../AudioPlayer';
import QueuePanel from '../QueuePanel';
import WishlistPanel from '../WishlistPanel';
import SkipLink from '../SkipLink';

interface LayoutProps {
  children: React.ReactNode;
  title?: string;
  description?: string;
}

export default function Layout({ children, title, description }: LayoutProps) {
  return (
    <ErrorBoundary>
      <div className="min-h-screen bg-gradient-to-br from-purple-50 via-white to-pink-50">
        <SEO title={title} description={description} />
        <SkipLink />
        
        <div className="relative">
          <div className="container mx-auto px-4 py-6 md:py-12">
            <Header />
            <main id="main-content">
              {children}
            </main>
          </div>
        </div>

        <Cart />
        <QueuePanel />
        <WishlistPanel />
        <AudioPlayer />
        <Footer />
      </div>
    </ErrorBoundary>
  );
}