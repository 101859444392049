import type { Song } from '../types/music';

export type SortField = 'price' | 'rating' | 'newest';
export type SortOrder = 'asc' | 'desc';

export function sortSongs(songs: Song[], field: SortField, order: SortOrder): Song[] {
  return [...songs].sort((a, b) => {
    let comparison = 0;
    
    switch (field) {
      case 'newest':
        // Higher IDs are newer songs
        comparison = b.id - a.id;
        break;
      case 'price':
        comparison = a.price - b.price;
        break;
      case 'rating':
        const ratingA = a.rating ?? 0;
        const ratingB = b.rating ?? 0;
        comparison = ratingB - ratingA; // Higher ratings first
        break;
      default:
        comparison = 0;
    }

    // Apply sort order
    return order === 'asc' ? -comparison : comparison;
  });
}