import type { Stripe } from '@stripe/stripe-js';

interface ErrorDetails {
  message?: string;
  name?: string;
  code?: string;
  stack?: string;
  type?: string;
  decline_code?: string;
  payment_intent?: unknown;
  raw?: unknown;
}

class Logger {
  private level: 'DEBUG' | 'INFO' | 'WARN' | 'ERROR' = 'INFO';
  private readonly isDevelopment = process.env.NODE_ENV === 'development';

  private formatStripeError(error: Stripe.StripeError): ErrorDetails {
    return {
      type: error.type,
      code: error.code,
      message: error.message,
      decline_code: error.decline_code,
      payment_intent: error.payment_intent
    };
  }

  private formatError(error: unknown): ErrorDetails {
    if (!error) return { message: 'Unknown error' };

    if (error instanceof Error) {
      const errorObj = {
        message: error.message,
        name: error.name,
        stack: this.isDevelopment ? error.stack : undefined,
        cause: error.cause
      };

      // Check if it's a Stripe error
      if ('type' in error && typeof error.type === 'string' && error.type.startsWith('stripe_')) {
        return this.formatStripeError(error as Stripe.StripeError);
      }

      return errorObj;
    }

    if (typeof error === 'string') {
      return { message: error };
    }

    return { raw: error };
  }

  private formatMessage(level: string, message: string, data?: unknown) {
    const timestamp = new Date().toISOString();
    return {
      timestamp,
      level,
      message,
      ...(data && { data })
    };
  }

  setLevel(level: 'DEBUG' | 'INFO' | 'WARN' | 'ERROR') {
    this.level = level;
  }

  debug(message: string, data?: unknown) {
    if (this.isDevelopment) {
      console.debug(this.formatMessage('DEBUG', message, data));
    }
  }

  info(message: string, data?: unknown) {
    console.info(this.formatMessage('INFO', message, data));
  }

  warn(message: string, data?: unknown) {
    console.warn(this.formatMessage('WARN', message, data));
  }

  error(message: string, error?: unknown, context?: Record<string, unknown>) {
    const errorDetails = error ? this.formatError(error) : undefined;
    
    console.error(this.formatMessage('ERROR', message, {
      error: errorDetails,
      ...(context && { context }),
      timestamp: new Date().toISOString()
    }));
  }
}

export const logger = new Logger();