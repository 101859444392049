import { create } from 'zustand';
import type { Song } from '../types/music';
import { sortSongs, type SortField, type SortOrder } from '../utils/sorting';
import { searchSongs, filterByCategories } from '../utils/search';

interface SearchState {
  searchTerm: string;
  selectedCategories: string[];
  sortBy: SortField;
  sortOrder: SortOrder;
  setSearchTerm: (term: string) => void;
  toggleCategory: (category: string) => void;
  setSortBy: (sort: SortField) => void;
  setSortOrder: (order: SortOrder) => void;
  searchSongs: (songs: Song[]) => Song[];
}

export const useSearchStore = create<SearchState>((set, get) => ({
  searchTerm: '',
  selectedCategories: [],
  sortBy: 'newest',
  sortOrder: 'desc',

  setSearchTerm: (term) => set({ searchTerm: term }),
  
  toggleCategory: (category) => set((state) => ({
    selectedCategories: state.selectedCategories.includes(category)
      ? state.selectedCategories.filter((c) => c !== category)
      : [...state.selectedCategories, category],
  })),

  setSortBy: (sort) => set({ sortBy: sort }),
  
  setSortOrder: (order) => set({ sortOrder: order }),

  searchSongs: (songs) => {
    const { searchTerm, selectedCategories, sortBy, sortOrder } = get();
    
    // Apply filters first
    let filteredSongs = [...songs];
    
    if (selectedCategories.length > 0) {
      filteredSongs = filterByCategories(filteredSongs, selectedCategories);
    }
    
    if (searchTerm.trim()) {
      filteredSongs = searchSongs(filteredSongs, searchTerm);
    }
    
    // Apply sorting last
    return sortSongs(filteredSongs, sortBy, sortOrder);
  },
}));