import { create } from 'zustand';
import type { Song } from '../types/music';
import { useRecentlyPlayedStore } from './recentlyPlayedStore';
import { audioService } from '../utils/audioService';

interface PlayerState {
  currentSong: Song | null;
  isPlaying: boolean;
  queue: Song[];
  setCurrentSong: (song: Song | null) => void;
  setIsPlaying: (isPlaying: boolean) => void;
  addToQueue: (song: Song) => void;
  removeFromQueue: (songId: number) => void;
  clearQueue: () => void;
  playNext: () => void;
  playPrevious: () => void;
  closePlayer: () => void;
  togglePlay: (song: Song) => void;
}

export const usePlayerStore = create<PlayerState>((set, get) => ({
  currentSong: null,
  isPlaying: false,
  queue: [],

  setCurrentSong: (song) => {
    if (song) {
      useRecentlyPlayedStore.getState().addToRecent(song);
      audioService.stopActiveAudio();
    }
    set({ currentSong: song });
  },

  setIsPlaying: (isPlaying) => {
    if (!isPlaying) {
      audioService.stopActiveAudio();
    }
    set({ isPlaying });
  },

  togglePlay: (song) => {
    const { currentSong, isPlaying } = get();
    if (currentSong?.id === song.id) {
      if (isPlaying) {
        audioService.stopActiveAudio();
      }
      set({ isPlaying: !isPlaying });
    } else {
      audioService.stopActiveAudio();
      set({ currentSong: song, isPlaying: true });
      useRecentlyPlayedStore.getState().addToRecent(song);
    }
  },
  
  addToQueue: (song) => set((state) => ({
    queue: [...state.queue, song]
  })),
  
  removeFromQueue: (songId) => set((state) => ({
    queue: state.queue.filter((song) => song.id !== songId)
  })),
  
  clearQueue: () => set({ queue: [] }),
  
  playNext: () => set((state) => {
    if (state.queue.length > 0) {
      const nextSong = state.queue[0];
      useRecentlyPlayedStore.getState().addToRecent(nextSong);
      audioService.stopActiveAudio();
      return {
        currentSong: nextSong,
        queue: state.queue.slice(1),
        isPlaying: true
      };
    }
    audioService.stopActiveAudio();
    return {
      currentSong: null,
      isPlaying: false
    };
  }),
  
  playPrevious: () => {
    audioService.stopActiveAudio();
    set((state) => ({
      isPlaying: !!state.currentSong
    }));
  },

  closePlayer: () => {
    audioService.stopActiveAudio();
    set({
      currentSong: null,
      isPlaying: false,
      queue: []
    });
  }
}));