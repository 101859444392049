import React, { useState, useEffect } from 'react';
import { ImageOff } from 'lucide-react';
import { getOptimizedImageUrl, getSrcSet } from '../utils/seo/imageOptimization';

interface ImageWithFallbackProps {
  src: string;
  alt: string;
  className?: string;
  width?: number;
  height?: number;
  loadingStrategy?: 'lazy' | 'eager';
  sizes?: string;
  priority?: boolean;
}

export default function ImageWithFallback({ 
  src, 
  alt, 
  className = '',
  width = 800,
  height,
  loadingStrategy = 'lazy',
  sizes = '(max-width: 768px) 100vw, 800px',
  priority = false
}: ImageWithFallbackProps) {
  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [optimizedSrc, setOptimizedSrc] = useState('');
  const [srcSet, setSrcSet] = useState('');

  useEffect(() => {
    if (!src) {
      setError(true);
      return;
    }

    try {
      // Generate optimized URLs
      const mainSrc = getOptimizedImageUrl(src, { width, height });
      const srcSetString = getSrcSet(src);
      
      setOptimizedSrc(mainSrc);
      setSrcSet(srcSetString);
      setError(false);
    } catch (err) {
      console.error('Image optimization failed:', err);
      setError(true);
    }
  }, [src, width, height]);

  if (error) {
    return (
      <div 
        className={`flex items-center justify-center bg-gray-100 ${className}`}
        role="img" 
        aria-label={alt}
        style={{ 
          aspectRatio: width && height ? `${width}/${height}` : '1',
          width: width ? `${width}px` : '100%'
        }}
      >
        <ImageOff className="w-8 h-8 text-gray-400" />
      </div>
    );
  }

  return (
    <div className="relative">
      {!loaded && (
        <div 
          className={`absolute inset-0 bg-gray-100 animate-pulse ${className}`}
          aria-hidden="true"
        />
      )}
      <picture>
        {/* WebP format */}
        <source
          type="image/webp"
          srcSet={srcSet}
          sizes={sizes}
        />
        
        {/* Fallback image */}
        <img
          src={optimizedSrc}
          alt={alt}
          width={width}
          height={height}
          loading={priority ? 'eager' : loadingStrategy}
          decoding={priority ? 'sync' : 'async'}
          onLoad={() => setLoaded(true)}
          onError={() => setError(true)}
          className={`transition-opacity duration-300 ${
            loaded ? 'opacity-100' : 'opacity-0'
          } ${className}`}
          sizes={sizes}
        />
      </picture>
    </div>
  );
}