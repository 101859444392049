import React from 'react';
import { Music4, Mail, Facebook, Twitter, Instagram, Youtube } from 'lucide-react';

export default function Footer() {
  return (
    <footer className="bg-white/80 backdrop-blur-lg mt-12">
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Brand Column */}
          <div className="space-y-4">
            <div className="flex items-center gap-2">
              <Music4 className="w-6 h-6 text-purple-600" />
              <h3 className="text-xl font-bold text-gradient">Sound Sprinkles</h3>
            </div>
            <p className="text-gray-600">
              Bringing joy and education through children's music since 2024.
            </p>
          </div>

          {/* Resources Column */}
          <div>
            <h4 className="font-semibold text-gray-800 mb-4">Teaching Resources</h4>
            <ul className="space-y-2">
              <li>
                <a 
                  href="/blog/music-education-tips"
                  className="text-gray-600 hover:text-purple-600 transition-colors"
                >
                  Music Education Tips
                </a>
              </li>
              <li>
                <a 
                  href="/lesson-plans"
                  className="text-gray-600 hover:text-purple-600 transition-colors"
                >
                  Free Lesson Plans
                </a>
              </li>
              <li>
                <a 
                  href="/teaching-guides"
                  className="text-gray-600 hover:text-purple-600 transition-colors"
                >
                  Teaching Guides
                </a>
              </li>
            </ul>
          </div>

          {/* Community Column */}
          <div>
            <h4 className="font-semibold text-gray-800 mb-4">Community</h4>
            <ul className="space-y-2">
              <li>
                <a 
                  href="https://teacherspayteachers.com/soundsprinkles"
                  className="text-gray-600 hover:text-purple-600 transition-colors"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Teachers Pay Teachers
                </a>
              </li>
              <li>
                <a 
                  href="https://pinterest.com/soundsprinkles"
                  className="text-gray-600 hover:text-purple-600 transition-colors"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Pinterest Resources
                </a>
              </li>
              <li>
                <a 
                  href="/testimonials"
                  className="text-gray-600 hover:text-purple-600 transition-colors"
                >
                  Teacher Testimonials
                </a>
              </li>
            </ul>
          </div>

          {/* Connect Column */}
          <div>
            <h4 className="font-semibold text-gray-800 mb-4">Connect With Us</h4>
            <div className="flex space-x-4">
              <a 
                href="https://facebook.com/soundsprinkles"
                className="text-gray-600 hover:text-purple-600 transition-colors"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook"
              >
                <Facebook className="w-5 h-5" />
              </a>
              <a 
                href="https://twitter.com/soundsprinkles"
                className="text-gray-600 hover:text-purple-600 transition-colors"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Twitter"
              >
                <Twitter className="w-5 h-5" />
              </a>
              <a 
                href="https://instagram.com/soundsprinkles"
                className="text-gray-600 hover:text-purple-600 transition-colors"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram"
              >
                <Instagram className="w-5 h-5" />
              </a>
              <a 
                href="https://youtube.com/soundsprinkles"
                className="text-gray-600 hover:text-purple-600 transition-colors"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="YouTube"
              >
                <Youtube className="w-5 h-5" />
              </a>
            </div>
            <div className="mt-4">
              <a 
                href="mailto:pete@soundsprinklesmusic.com"
                className="flex items-center gap-2 text-gray-600 hover:text-purple-600 transition-colors"
              >
                <Mail className="w-5 h-5" />
                <span>pete@soundsprinklesmusic.com</span>
              </a>
            </div>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="mt-12 pt-8 border-t border-gray-200">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <p className="text-gray-600 text-sm">
              © {new Date().getFullYear()} Sound Sprinkles Music. All rights reserved.
            </p>
            <div className="flex gap-6 text-sm">
              <a 
                href="/privacy"
                className="text-gray-600 hover:text-purple-600 transition-colors"
              >
                Privacy Policy
              </a>
              <a 
                href="/terms"
                className="text-gray-600 hover:text-purple-600 transition-colors"
              >
                Terms of Service
              </a>
              <a 
                href="/licensing"
                className="text-gray-600 hover:text-purple-600 transition-colors"
              >
                Licensing
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}