import Fuse from 'fuse.js';
import type { Song } from '../types/music';

const fuseOptions = {
  keys: ['title', 'description', 'category', 'tags'],
  threshold: 0.3,
  includeScore: true,
  useExtendedSearch: true,
  ignoreLocation: true,
  findAllMatches: true
};

export function searchSongs(songs: Song[], searchTerm: string): Song[] {
  if (!searchTerm.trim()) return songs;
  
  const fuse = new Fuse(songs, fuseOptions);
  return fuse.search(searchTerm).map(result => result.item);
}

export function filterByCategories(songs: Song[], categories: string[]): Song[] {
  if (categories.length === 0) return songs;
  return songs.filter(song => categories.includes(song.category));
}