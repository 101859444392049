import React from 'react';
import { Helmet } from 'react-helmet-async';
import type { Song } from '../../types/music';

interface SchemaMarkupProps {
  song?: Song;
  breadcrumbs?: {
    category: string;
    songTitle?: string;
  };
}

export default function SchemaMarkup({ song, breadcrumbs }: SchemaMarkupProps) {
  const organizationSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Sound Sprinkles Music",
    "url": "https://soundsprinkles.com",
    "logo": "https://soundsprinkles.com/logo.png",
    "sameAs": [
      "https://facebook.com/soundsprinkles",
      "https://twitter.com/soundsprinkles",
      "https://instagram.com/soundsprinkles"
    ],
    "description": "Educational children's music, dance songs, and lullabies for kids aged 0-8. Perfect for parents, teachers, and daycares.",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+1-555-555-5555",
      "contactType": "customer service",
      "email": "pete@soundsprinklesmusic.com",
      "availableLanguage": "English"
    }
  };

  const websiteSchema = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "Sound Sprinkles Music",
    "url": "https://soundsprinkles.com",
    "description": "Download children's dance music, educational songs, and lullabies. Features toddler dance songs, preschool movement music, and interactive learning songs.",
    "keywords": "children's dance music, toddler dance songs, kids dance songs, educational music for kids, preschool dance songs, movement songs for kids",
    "potentialAction": {
      "@type": "SearchAction",
      "target": "https://soundsprinkles.com/search?q={search_term_string}",
      "query-input": "required name=search_term_string"
    }
  };

  const breadcrumbSchema = breadcrumbs ? {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://soundsprinkles.com"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": breadcrumbs.category,
        "item": `https://soundsprinkles.com/categories/${breadcrumbs.category.toLowerCase().replace(/\s+/g, '-')}`
      },
      ...(breadcrumbs.songTitle ? [{
        "@type": "ListItem",
        "position": 3,
        "name": breadcrumbs.songTitle,
        "item": `https://soundsprinkles.com/songs/${breadcrumbs.songTitle.toLowerCase().replace(/\s+/g, '-')}`
      }] : [])
    ]
  } : null;

  const songSchema = song ? {
    "@context": "https://schema.org",
    "@type": "MusicRecording",
    "name": song.title,
    "description": song.description,
    "image": song.imageUrl,
    "genre": [song.category, "Children's Music", "Kids Music", "Educational Music"],
    "duration": song.duration,
    "inLanguage": "en",
    "audience": {
      "@type": "PeopleAudience",
      "suggestedMinAge": "0",
      "suggestedMaxAge": "8"
    },
    "offers": {
      "@type": "Offer",
      "price": song.price,
      "priceCurrency": "USD",
      "availability": "https://schema.org/InStock",
      "url": `https://soundsprinkles.com/songs/${song.title.toLowerCase().replace(/\s+/g, '-')}`,
      "priceValidUntil": new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
    },
    "aggregateRating": song.rating ? {
      "@type": "AggregateRating",
      "ratingValue": song.rating,
      "bestRating": "5",
      "worstRating": "1",
      "ratingCount": "100"
    } : undefined,
    "publisher": {
      "@type": "Organization",
      "name": "Sound Sprinkles Music",
      "url": "https://soundsprinkles.com"
    },
    "keywords": [
      "children's dance music",
      "toddler dance songs",
      "kids dance songs",
      "educational music for kids",
      "preschool dance songs",
      "movement songs for kids",
      ...song.tags || []
    ].join(', ')
  } : null;

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(organizationSchema)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(websiteSchema)}
      </script>
      {breadcrumbSchema && (
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbSchema)}
        </script>
      )}
      {songSchema && (
        <script type="application/ld+json">
          {JSON.stringify(songSchema)}
        </script>
      )}
    </Helmet>
  );
}