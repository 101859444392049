import { logger } from './logger';

class AudioService {
  private static instance: AudioService;
  private audioContext: AudioContext | null = null;
  private activeAudio: HTMLAudioElement | null = null;
  private unlocked: boolean = false;

  private constructor() {
    this.initAudioContext();
    this.setupUnlockHandlers();
    this.setupMobileHandlers();
  }

  static getInstance(): AudioService {
    if (!AudioService.instance) {
      AudioService.instance = new AudioService();
    }
    return AudioService.instance;
  }

  private initAudioContext() {
    try {
      const AudioContext = window.AudioContext || (window as any).webkitAudioContext;
      if (AudioContext) {
        this.audioContext = new AudioContext();
      }
    } catch (error) {
      logger.warn('AudioContext not supported:', error);
    }
  }

  private setupUnlockHandlers() {
    if (typeof window === 'undefined') return;

    const unlockAudio = () => {
      if (this.unlocked) return;

      if (this.audioContext) {
        const buffer = this.audioContext.createBuffer(1, 1, 22050);
        const source = this.audioContext.createBufferSource();
        source.buffer = buffer;
        source.connect(this.audioContext.destination);
        source.start(0);
      }

      this.unlocked = true;
    };

    const events = ['touchstart', 'touchend', 'mousedown', 'keydown'];
    events.forEach(event => {
      document.addEventListener(event, unlockAudio, { once: true });
    });
  }

  private setupMobileHandlers() {
    if (typeof window === 'undefined') return;

    document.addEventListener('visibilitychange', () => {
      if (document.hidden && this.activeAudio) {
        this.activeAudio.pause();
      }
    });

    window.addEventListener('pagehide', () => {
      if (this.activeAudio) {
        this.activeAudio.pause();
      }
    });
  }

  public async resumeAudioContext(): Promise<void> {
    if (this.audioContext?.state === 'suspended') {
      try {
        await this.audioContext.resume();
      } catch (error) {
        logger.error('Failed to resume AudioContext:', error);
      }
    }
  }

  public setActiveAudio(audio: HTMLAudioElement | null) {
    // Stop previous audio if exists and different from new audio
    if (this.activeAudio && this.activeAudio !== audio) {
      this.activeAudio.pause();
      this.activeAudio.currentTime = 0;
    }
    this.activeAudio = audio;
  }

  public stopActiveAudio() {
    if (this.activeAudio) {
      this.activeAudio.pause();
      this.activeAudio.currentTime = 0;
      this.activeAudio = null;
    }
  }

  public isUnlocked(): boolean {
    return this.unlocked;
  }
}

export const audioService = AudioService.getInstance();