import React from 'react';
import { Helmet } from 'react-helmet-async';
import { generateMetaDescription } from '../../utils/seo';
import type { Song } from '../../types/music';

interface MetaTagsProps {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
  type?: string;
  song?: Song;
}

export default function MetaTags({
  title = "Children's Music Downloads | Educational Songs & Lullabies | Sound Sprinkles",
  description,
  image = "https://images.unsplash.com/photo-1503095396549-807759245b35?w=1200",
  url = "https://soundsprinkles.com",
  type = "website",
  song
}: MetaTagsProps) {
  const metaDescription = song ? generateMetaDescription(song) : description;

  return (
    <Helmet>
      {/* Primary Meta Tags */}
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={metaDescription} />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content={type} />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={image} />
      <meta property="og:site_name" content="Sound Sprinkles Music" />
      <meta property="og:locale" content="en_US" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@soundsprinkles" />
      <meta name="twitter:creator" content="@soundsprinkles" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={image} />
      
      {/* Additional SEO Tags */}
      <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
      <meta name="author" content="Sound Sprinkles Music" />
      <meta name="copyright" content="Sound Sprinkles Music" />
      <meta name="language" content="English" />
      <meta name="revisit-after" content="1 day" />
      <meta name="rating" content="General" />
      <meta name="geo.region" content="US" />
      
      {/* Age-Specific Keywords */}
      {song?.ageGroups && (
        <meta name="keywords" content={`
          children's music, kids songs, educational music,
          ${song.ageGroups.join(' music, ')} music,
          ${song.category.toLowerCase()}, learning songs,
          age-appropriate music, child development music
        `} />
      )}
      
      <link rel="canonical" href={url} />
    </Helmet>
  );
}