interface ImageOptimizationOptions {
  width?: number;
  height?: number;
  quality?: number;
  format?: 'webp' | 'jpeg' | 'png';
}

export function getOptimizedImageUrl(url: string, options: ImageOptimizationOptions = {}): string {
  const { width = 800, height, quality = 80, format = 'webp' } = options;
  
  try {
    const imageUrl = new URL(url);
    
    // Handle different image providers
    if (url.includes('unsplash.com')) {
      // Unsplash image optimization
      imageUrl.searchParams.set('auto', 'format,compress');
      imageUrl.searchParams.set('q', quality.toString());
      imageUrl.searchParams.set('w', width.toString());
      if (height) imageUrl.searchParams.set('h', height.toString());
      imageUrl.searchParams.set('fit', 'crop');
    } else if (url.includes('storage.googleapis.com')) {
      // Google Cloud Storage images
      if (!url.includes('?')) {
        return `${url}?width=${width}${height ? `&height=${height}` : ''}&quality=${quality}`;
      }
    } else if (url.includes('cdn2.suno.ai')) {
      // Handle Suno AI CDN images
      return url; // Already optimized
    }
    
    return imageUrl.toString();
  } catch (error) {
    console.error('Error optimizing image URL:', error);
    return url; // Return original URL if optimization fails
  }
}

export function getSrcSet(url: string, sizes: number[] = [400, 800, 1200]): string {
  return sizes
    .map(size => {
      const optimizedUrl = getOptimizedImageUrl(url, { width: size });
      return `${optimizedUrl} ${size}w`;
    })
    .join(', ');
}