import { logger } from './logger';
import type { Song } from '../types/music';

// Age-specific keyword mappings
const ageKeywords = {
  infant: [
    'baby music',
    'infant songs',
    'newborn lullabies',
    '0-2 years music',
    'baby sleep music',
    'infant development songs',
    'baby sensory music',
    'nursery songs for infants'
  ],
  toddler: [
    'toddler songs',
    '2-4 years music',
    'toddler dance songs',
    'music for toddlers',
    'early learning songs',
    'toddler movement music',
    'preschool preparation songs',
    'toddler educational music'
  ],
  preschool: [
    'preschool music',
    '4-6 years songs',
    'kindergarten music',
    'preschool learning songs',
    'educational music for preschoolers',
    'preschool dance songs',
    'interactive preschool music',
    'early childhood education songs'
  ],
  elementary: [
    'elementary school music',
    '6-8 years songs',
    'grade school music',
    'primary school songs',
    'elementary education music',
    'music for young learners',
    'elementary dance songs',
    'classroom music elementary'
  ],
  tween: [
    'tween music',
    '9-12 years songs',
    'preteen music',
    'music for older kids',
    'tween educational songs',
    'tween dance music',
    'middle school music',
    'age-appropriate tween songs'
  ]
};

// Generate descriptive alt text for song images
export function generateSongAltText(song: Song): string {
  const ageGroupText = song.ageGroups?.length 
    ? `for ${song.ageGroups.join(' and ')} aged children` 
    : 'for children';
    
  const categoryText = song.category.toLowerCase();
  const keywords = song.ageGroups?.flatMap(age => ageKeywords[age]) || [];
  
  return `${song.title} - ${categoryText} song ${ageGroupText}. Perfect for ${keywords.slice(0, 3).join(', ')}. ${song.description || ''}`.trim();
}

// Generate meta description with age-specific keywords
export function generateMetaDescription(song: Song): string {
  const keywords = song.ageGroups?.flatMap(age => ageKeywords[age].slice(0, 3)) || [];
  return `Download "${song.title}" - ${song.category} music perfect for ${keywords.join(', ')}. ${song.description || ''}`.slice(0, 155);
}

// Generate schema markup with age-specific targeting
export function generateSongSchema(song: Song) {
  const ageRanges = {
    infant: { min: 0, max: 2 },
    toddler: { min: 2, max: 4 },
    preschool: { min: 4, max: 6 },
    elementary: { min: 6, max: 8 },
    tween: { min: 9, max: 12 }
  };

  const targetAgeRange = song.ageGroups?.map(age => ageRanges[age]) || [];
  const minAge = Math.min(...targetAgeRange.map(range => range.min));
  const maxAge = Math.max(...targetAgeRange.map(range => range.max));

  return {
    "@context": "https://schema.org",
    "@type": "MusicRecording",
    "name": song.title,
    "description": generateMetaDescription(song),
    "image": song.imageUrl,
    "genre": [song.category, "Children's Music", ...song.ageGroups?.map(age => `${age} Music`) || []],
    "duration": song.duration,
    "inLanguage": "en",
    "keywords": song.ageGroups?.flatMap(age => ageKeywords[age]).join(', '),
    "audience": {
      "@type": "PeopleAudience",
      "suggestedMinAge": minAge.toString(),
      "suggestedMaxAge": maxAge.toString()
    }
  };
}

export function trackSEOMetrics() {
  try {
    // Track Core Web Vitals
    if ('web-vital' in window) {
      // @ts-ignore
      webVitals.getCLS(logger.info);
      // @ts-ignore
      webVitals.getFID(logger.info);
      // @ts-ignore
      webVitals.getLCP(logger.info);
    }
  } catch (error) {
    logger.error('Failed to track SEO metrics:', error);
  }
}