import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import type { Song } from '../types/music';

interface RecentlyPlayedState {
  recentlyPlayed: Song[];
  addToRecent: (song: Song) => void;
  clearRecent: () => void;
}

const MAX_RECENT = 10;

export const useRecentlyPlayedStore = create<RecentlyPlayedState>()(
  persist(
    (set) => ({
      recentlyPlayed: [],
      addToRecent: (song) => set((state) => {
        const filtered = state.recentlyPlayed.filter(s => s.id !== song.id);
        return {
          recentlyPlayed: [song, ...filtered].slice(0, MAX_RECENT)
        };
      }),
      clearRecent: () => set({ recentlyPlayed: [] })
    }),
    {
      name: 'recently-played-storage'
    }
  )
);