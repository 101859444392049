import React, { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import LoadingFallback from './components/LoadingFallback';
import Layout from './components/layout/Layout';

const HomePage = React.lazy(() => import('./pages/HomePage'));
const StripeProvider = React.lazy(() => import('./components/StripeProvider'));

export default function App() {
  return (
    <ErrorBoundary>
      <HelmetProvider>
        <Router>
          <Suspense fallback={<LoadingFallback />}>
            <StripeProvider>
              <Layout>
                <Routes>
                  <Route path="/" element={<HomePage />} />
                </Routes>
              </Layout>
            </StripeProvider>
          </Suspense>
        </Router>
      </HelmetProvider>
    </ErrorBoundary>
  );
}