import React, { useEffect, useRef, useState } from 'react';
import { Volume2, VolumeX, Play, Pause, SkipBack, SkipForward, Repeat, Shuffle, X } from 'lucide-react';
import type { Song } from '../types/music';
import { useRecentlyPlayedStore } from '../store/recentlyPlayedStore';
import { usePlayerStore } from '../store/playerStore';
import ImageWithFallback from './ImageWithFallback';
import { formatDuration } from '../utils/audio';
import { useMediaQuery } from '../hooks/useMediaQuery';
import TouchFriendlyButton from './TouchFriendlyButton';
import { audioService } from '../utils/audioService';

export default function AudioPlayer() {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [volume, setVolume] = useState(0.7);
  const [isMuted, setIsMuted] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isRepeat, setIsRepeat] = useState(false);
  const [isShuffle, setIsShuffle] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)');

  const { 
    currentSong,
    isPlaying,
    setIsPlaying,
    playNext,
    playPrevious,
    closePlayer
  } = usePlayerStore();

  useEffect(() => {
    if (currentSong && audioRef.current) {
      audioRef.current.src = currentSong.previewUrl;
      audioRef.current.load();
      audioService.setActiveAudio(audioRef.current);
      if (isPlaying) {
        audioRef.current.play().catch(() => {
          setIsPlaying(false);
        });
      }
    }
  }, [currentSong]);

  useEffect(() => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.play().catch(() => {
          setIsPlaying(false);
        });
      } else {
        audioRef.current.pause();
      }
    }
  }, [isPlaying]);

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
      setDuration(audioRef.current.duration);
    }
  };

  const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => {
    const time = parseFloat(e.target.value);
    setCurrentTime(time);
    if (audioRef.current) {
      audioRef.current.currentTime = time;
    }
  };

  const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);
    setVolume(value);
    if (audioRef.current) {
      audioRef.current.volume = value;
    }
    setIsMuted(value === 0);
  };

  const toggleMute = () => {
    if (audioRef.current) {
      if (isMuted) {
        audioRef.current.volume = volume;
        setIsMuted(false);
      } else {
        audioRef.current.volume = 0;
        setIsMuted(true);
      }
    }
  };

  const handleClose = () => {
    audioService.stopActiveAudio();
    closePlayer();
  };

  if (!currentSong) return null;

  return (
    <div className={`fixed bottom-0 left-0 right-0 bg-white/95 backdrop-blur-lg border-t border-gray-200 p-4 shadow-lg z-50 ${isMobile ? 'mobile-safe-bottom' : ''}`}>
      <div className={`max-w-7xl mx-auto flex ${isMobile ? 'flex-col gap-2' : 'items-center gap-4'}`}>
        {/* Song Info */}
        <div className={`flex items-center gap-3 ${isMobile ? 'w-full' : 'w-1/4'}`}>
          <div className="w-12 h-12 rounded-lg overflow-hidden">
            <ImageWithFallback
              src={currentSong.imageUrl}
              alt={currentSong.title}
              className="w-full h-full object-cover"
            />
          </div>
          <div className="flex-1 min-w-0">
            <h4 className="font-medium text-gray-800 truncate">{currentSong.title}</h4>
            <p className="text-sm text-gray-500 truncate">{currentSong.category}</p>
          </div>
        </div>

        {/* Controls */}
        <div className={`flex-1 flex flex-col items-center gap-2 ${isMobile ? 'w-full' : ''}`}>
          <div className="flex items-center gap-4">
            <TouchFriendlyButton
              onClick={() => setIsShuffle(!isShuffle)}
              className={`p-2 rounded-full ${
                isShuffle ? 'text-purple-600' : 'text-gray-400'
              } hover:text-purple-600 transition-colors`}
            >
              <Shuffle className="w-5 h-5" />
            </TouchFriendlyButton>
            <TouchFriendlyButton
              onClick={playPrevious}
              className="p-2 rounded-full text-gray-600 hover:text-purple-600 transition-colors"
            >
              <SkipBack className="w-5 h-5" />
            </TouchFriendlyButton>
            <TouchFriendlyButton
              onClick={() => setIsPlaying(!isPlaying)}
              className="p-3 bg-purple-600 rounded-full text-white hover:bg-purple-700 transition-colors"
            >
              {isPlaying ? (
                <Pause className="w-6 h-6" />
              ) : (
                <Play className="w-6 h-6" />
              )}
            </TouchFriendlyButton>
            <TouchFriendlyButton
              onClick={playNext}
              className="p-2 rounded-full text-gray-600 hover:text-purple-600 transition-colors"
            >
              <SkipForward className="w-5 h-5" />
            </TouchFriendlyButton>
            <TouchFriendlyButton
              onClick={() => setIsRepeat(!isRepeat)}
              className={`p-2 rounded-full ${
                isRepeat ? 'text-purple-600' : 'text-gray-400'
              } hover:text-purple-600 transition-colors`}
            >
              <Repeat className="w-5 h-5" />
            </TouchFriendlyButton>
          </div>

          {/* Progress Bar */}
          <div className="w-full flex items-center gap-3 px-4">
            <span className="text-sm text-gray-500 w-12 text-right">
              {formatDuration(currentTime)}
            </span>
            <input
              type="range"
              min={0}
              max={duration || 0}
              value={currentTime}
              onChange={handleSeek}
              className="flex-1 h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
            />
            <span className="text-sm text-gray-500 w-12">
              {formatDuration(duration)}
            </span>
          </div>
        </div>

        {/* Volume Control and Close Button */}
        <div className={`${isMobile ? 'w-full' : 'w-1/4'} flex items-center justify-end gap-2`}>
          {!isMobile && (
            <>
              <TouchFriendlyButton
                onClick={toggleMute}
                className="p-2 rounded-full text-gray-600 hover:text-purple-600 transition-colors"
              >
                {isMuted ? (
                  <VolumeX className="w-5 h-5" />
                ) : (
                  <Volume2 className="w-5 h-5" />
                )}
              </TouchFriendlyButton>
              <input
                type="range"
                min={0}
                max={1}
                step={0.01}
                value={isMuted ? 0 : volume}
                onChange={handleVolumeChange}
                className="w-24 h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
              />
            </>
          )}
          <TouchFriendlyButton
            onClick={handleClose}
            className="ml-4 p-2 rounded-full text-gray-600 hover:text-red-500 transition-colors"
            aria-label="Close player"
          >
            <X className="w-5 h-5" />
          </TouchFriendlyButton>
        </div>

        <audio
          ref={audioRef}
          onTimeUpdate={handleTimeUpdate}
          onEnded={playNext}
          loop={isRepeat}
        />
      </div>
    </div>
  );
}