import React from 'react';

interface TouchFriendlyButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  className?: string;
}

export default function TouchFriendlyButton({
  children,
  className = '',
  ...props
}: TouchFriendlyButtonProps) {
  return (
    <button
      {...props}
      className={`min-h-[44px] min-w-[44px] touch-manipulation tap-highlight-none ${className}`}
    >
      {children}
    </button>
  );
}