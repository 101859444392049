import { logger } from './logger';

export async function getAudioDuration(url: string): Promise<string> {
  return new Promise((resolve) => {
    const audio = new Audio();
    
    const handleLoadedMetadata = () => {
      const duration = audio.duration;
      const minutes = Math.floor(duration / 60);
      const seconds = Math.floor(duration % 60);
      cleanup();
      resolve(`${minutes}:${seconds.toString().padStart(2, '0')}`);
    };

    const handleError = () => {
      logger.warn('Failed to load audio duration:', url);
      cleanup();
      resolve('0:00');
    };

    const cleanup = () => {
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      audio.removeEventListener('error', handleError);
      URL.revokeObjectURL(audio.src);
    };

    audio.addEventListener('loadedmetadata', handleLoadedMetadata);
    audio.addEventListener('error', handleError);
    
    audio.src = url;
    audio.load();

    // Set a timeout to prevent hanging
    setTimeout(() => {
      cleanup();
      resolve('0:00');
    }, 5000);
  });
}

export function formatDuration(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
}