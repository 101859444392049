import { create } from 'zustand';
import type { Song } from '../types/music';

interface CartState {
  items: Song[];
  total: number;
  addToCart: (song: Song) => void;
  removeFromCart: (songId: number) => void;
  clearCart: () => void;
}

export const useCartStore = create<CartState>((set) => ({
  items: [],
  total: 0,
  addToCart: (song) =>
    set((state) => {
      if (!song?.id || state.items.some((item) => item.id === song.id)) {
        return state;
      }
      return {
        items: [...state.items, song],
        total: Number((state.total + song.price).toFixed(2))
      };
    }),
  removeFromCart: (songId) =>
    set((state) => {
      const item = state.items.find((item) => item.id === songId);
      if (!item) return state;
      return {
        items: state.items.filter((item) => item.id !== songId),
        total: Number((state.total - item.price).toFixed(2))
      };
    }),
  clearCart: () => set({ items: [], total: 0 })
}));