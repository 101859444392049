import React from 'react';
import MetaTags from '../seo/MetaTags';
import SchemaMarkup from '../seo/SchemaMarkup';
import type { Song } from '../../types/music';

interface SEOProps {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
  song?: Song;
}

export default function SEO({ title, description, image, url, song }: SEOProps) {
  return (
    <>
      <MetaTags
        title={title}
        description={description}
        image={image}
        url={url}
        type={song ? 'music.song' : 'website'}
      />
      <SchemaMarkup song={song} />
    </>
  );
}