import React from 'react';
import { Music4, Sparkles, Search } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useSearchStore } from '../../store/searchStore';

export default function Header() {
  const { setSearchTerm } = useSearchStore();

  return (
    <header className="relative z-10 mb-8">
      {/* Background decoration */}
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <div className="absolute -top-40 -right-32 w-96 h-96 bg-purple-200 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-float"></div>
        <div className="absolute -top-40 -left-32 w-96 h-96 bg-pink-200 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-float" style={{ animationDelay: '-2s' }}></div>
      </div>

      <div className="container mx-auto px-4 pt-8 pb-12">
        <div className="flex flex-col items-center text-center">
          <Link to="/" className="flex items-center gap-3 mb-6 hover-lift">
            <Music4 className="w-12 h-12 text-purple-600 animate-float" />
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold bg-gradient-to-r from-purple-600 via-pink-500 to-purple-600 text-transparent bg-clip-text">
              Sound Sprinkles
            </h1>
            <Sparkles className="w-12 h-12 text-pink-500 animate-float" style={{ animationDelay: '-1.5s' }} />
          </Link>

          <p className="text-xl md:text-2xl text-gray-600 mb-8 max-w-2xl">
            Where Little Ears Discover Big Joy! Educational songs, lullabies, and fun dance music for kids.
          </p>

          <div className="w-full max-w-2xl relative">
            <input
              type="search"
              placeholder="Search for songs, categories, or themes..."
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full px-6 py-4 pr-12 rounded-2xl bg-white/80 backdrop-blur-sm border border-purple-100 focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50 transition-all duration-300 placeholder-gray-400"
            />
            <Search className="absolute right-4 top-1/2 -translate-y-1/2 w-6 h-6 text-gray-400" />
          </div>
        </div>
      </div>
    </header>
  );
}