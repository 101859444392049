import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import type { Song } from '../types/music';

interface WishlistState {
  items: Song[];
  addToWishlist: (song: Song) => void;
  removeFromWishlist: (songId: number) => void;
  isInWishlist: (songId: number) => boolean;
  clearWishlist: () => void;
}

export const useWishlistStore = create<WishlistState>()(
  persist(
    (set, get) => ({
      items: [],
      addToWishlist: (song) => set((state) => ({
        items: [...state.items, song]
      })),
      removeFromWishlist: (songId) => set((state) => ({
        items: state.items.filter((item) => item.id !== songId)
      })),
      isInWishlist: (songId) => get().items.some((item) => item.id === songId),
      clearWishlist: () => set({ items: [] })
    }),
    {
      name: 'wishlist-storage'
    }
  )
);