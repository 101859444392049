import React, { useState } from 'react';
import { ShoppingCart, X, Download, Loader2 } from 'lucide-react';
import { useStripe } from '@stripe/react-stripe-js';
import { useCartStore } from '../store/cartStore';
import { logger } from '../utils/logger';

export default function Cart() {
  const stripe = useStripe();
  const { items, total, removeFromCart } = useCartStore();
  const [isOpen, setIsOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleCheckout = async () => {
    if (!stripe) {
      setError('Payment system is not available');
      return;
    }

    if (items.length === 0) {
      setError('Your cart is empty');
      return;
    }

    try {
      setError(null);
      setIsProcessing(true);

      const lineItems = items.map((item) => ({
        price: item.price,
        quantity: 1,
        title: item.title,
        imageUrl: item.imageUrl,
        downloadUrl: item.downloadUrl,
      }));

      logger.info('Starting checkout', {
        itemCount: items.length,
        total,
        lineItems: lineItems.map((item) => ({ price: item.price })),
      });

      const response = await fetch(
        'https://soundsprinkle-api-db407d547ed6.herokuapp.com/create-checkout-session',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ lineItems }),
        }
      );

      if (!response.ok) {
        throw new Error(`Payment failed (${response.status})`);
      }

      const { sessionId, checkoutError } = await response.json();

      if (sessionId) {
        const { error } = await stripe.redirectToCheckout({ sessionId });
        if (error) {
          throw error;
        }
      } else if (checkoutError) {
        throw checkoutError;
      }
    } catch (error) {
      logger.error('Checkout error:', error);
      setError('Unable to process payment. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="fixed bottom-4 right-4 bg-gradient-to-r from-purple-600 to-pink-500 text-white p-3 rounded-full shadow-lg hover:shadow-xl transition-all duration-300 z-50"
        aria-label="Open cart"
      >
        <div className="relative">
          <ShoppingCart className="w-6 h-6" />
          {items.length > 0 && (
            <span className="absolute -top-2 -right-2 bg-white text-purple-600 text-xs w-5 h-5 rounded-full flex items-center justify-center font-bold">
              {items.length}
            </span>
          )}
        </div>
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-black/60 backdrop-blur-sm z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-xl w-full max-w-md max-h-[90vh] flex flex-col">
            <div className="p-4 border-b border-gray-200">
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-bold text-gradient">Your Cart</h2>
                <button
                  onClick={() => setIsOpen(false)}
                  className="text-gray-500 hover:text-gray-700 transition-colors"
                >
                  <X className="w-6 h-6" />
                </button>
              </div>
            </div>

            <div className="flex-1 overflow-y-auto p-4">
              {items.length === 0 ? (
                <p className="text-gray-500 text-center py-8">
                  Your cart is empty
                </p>
              ) : (
                <div className="space-y-4">
                  {items.map((item) => (
                    <div
                      key={item.id}
                      className="flex items-center justify-between bg-gray-50 p-4 rounded-xl"
                    >
                      <div className="flex items-center gap-4">
                        <img
                          src={item.imageUrl}
                          alt=""
                          className="w-16 h-16 rounded-lg object-cover"
                        />
                        <div>
                          <h3 className="font-semibold text-gray-800">
                            {item.title}
                          </h3>
                          <p className="text-purple-600 font-medium">
                            ${item.price.toFixed(2)}
                          </p>
                        </div>
                      </div>
                      <button
                        onClick={() => removeFromCart(item.id)}
                        className="text-red-500 hover:text-red-700 p-2 hover:bg-red-50 rounded-lg transition-colors"
                      >
                        <X className="w-5 h-5" />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="p-4 bg-gray-50 rounded-b-xl">
              {error && (
                <div
                  className="mb-4 p-3 bg-red-50 text-red-700 rounded-lg text-sm"
                  role="alert"
                >
                  {error}
                </div>
              )}

              <div className="flex justify-between items-center mb-4">
                <span className="text-lg font-medium">Total:</span>
                <span className="text-2xl font-bold text-gradient">
                  ${total.toFixed(2)}
                </span>
              </div>

              <button
                onClick={handleCheckout}
                disabled={isProcessing || items.length === 0 || !stripe}
                className="w-full bg-gradient-to-r from-purple-600 to-pink-500 text-white py-3 px-6 rounded-xl hover:shadow-lg transition-all duration-300 flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isProcessing ? (
                  <>
                    <Loader2 className="w-5 h-5 animate-spin" />
                    Processing...
                  </>
                ) : (
                  <>
                    <Download className="w-5 h-5" />
                    Checkout & Download
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
