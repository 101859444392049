import React from 'react';
import { Bookmark, X, Play } from 'lucide-react';
import { useWishlistStore } from '../store/wishlistStore';
import { usePlayerStore } from '../store/playerStore';
import ImageWithFallback from './ImageWithFallback';

export default function WishlistPanel() {
  const [isOpen, setIsOpen] = React.useState(false);
  const { items, removeFromWishlist, clearWishlist } = useWishlistStore();
  const { setCurrentSong, setIsPlaying } = usePlayerStore();

  if (items.length === 0) return null;

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="fixed bottom-24 right-20 bg-gradient-to-r from-purple-600 to-pink-500 text-white p-3 rounded-full shadow-lg hover:shadow-xl transition-all duration-300"
        aria-label="Open wishlist"
      >
        <Bookmark className="w-6 h-6" />
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-black/60 backdrop-blur-sm z-50 flex items-end sm:items-center justify-center p-4">
          <div className="bg-white rounded-t-2xl sm:rounded-2xl w-full max-w-md max-h-[80vh] flex flex-col">
            <div className="p-4 border-b border-gray-200">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <Bookmark className="w-5 h-5 text-purple-600" />
                  <h2 className="text-xl font-bold text-gradient">Wishlist</h2>
                </div>
                <div className="flex items-center gap-2">
                  <button
                    onClick={clearWishlist}
                    className="text-sm text-gray-500 hover:text-purple-600 transition-colors"
                  >
                    Clear All
                  </button>
                  <button
                    onClick={() => setIsOpen(false)}
                    className="text-gray-500 hover:text-gray-700 transition-colors"
                  >
                    <X className="w-6 h-6" />
                  </button>
                </div>
              </div>
            </div>

            <div className="flex-1 overflow-y-auto p-4">
              {items.length === 0 ? (
                <p className="text-gray-500 text-center py-8">Wishlist is empty</p>
              ) : (
                <div className="space-y-4">
                  {items.map((song) => (
                    <div
                      key={song.id}
                      className="flex items-center gap-3 bg-gray-50 p-3 rounded-xl"
                    >
                      <div className="w-12 h-12 rounded-lg overflow-hidden flex-shrink-0">
                        <ImageWithFallback
                          src={song.imageUrl}
                          alt={song.title}
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <div className="flex-1 min-w-0">
                        <h3 className="font-medium text-gray-800 truncate">
                          {song.title}
                        </h3>
                        <p className="text-sm text-gray-500 truncate">
                          {song.category}
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <button
                          onClick={() => {
                            setCurrentSong(song);
                            setIsPlaying(true);
                          }}
                          className="p-2 text-purple-600 hover:bg-purple-50 rounded-full transition-colors"
                        >
                          <Play className="w-5 h-5" />
                        </button>
                        <button
                          onClick={() => removeFromWishlist(song.id)}
                          className="p-2 text-gray-400 hover:text-red-500 transition-colors"
                        >
                          <X className="w-5 h-5" />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}